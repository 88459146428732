import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AccountContext from '../../../context/private/accountContext';
import IslandsContext from '../../../context/private/islandsContext';
import { Container } from '../../../styles/Styles';
import { timeToYYYYMMDD } from '../../../utils/tools';
import Layout from '../../layout/Index';

const UpdateSingle = () => {
  const { islandId, slugId } = useParams();
  const { updateDate, getSingleIslandUpdate } = useContext(IslandsContext);
  const { loadUserInfo } = useContext(AccountContext);
  useEffect(() => {
    getSingleIslandUpdate(islandId, slugId);
    loadUserInfo();
  }, [islandId, slugId]);
  // useEffect(() => {
  //   getSingleIslandUpdate(islandId, slugId);
  // }, []);

  return (
    <Layout className="over">
      <Container
        x={'center'}
        y={'center'}
        className="container over-container flex items-start"
      >
        {updateDate?.item?.length === 0 ? (
          <div className="w-full rounded-3xl bg-white p-16 items-center justify-center text-3xl text-[#0e608b] font-bold flex h-full">
            No update content is available
          </div>
        ) : (
          <div className="w-full rounded-3xl bg-white p-16 items-start justify-start">
            <div className="text-3xl text-[#0e608b] font-bold flex justify-between update-single-title">
              {updateDate?.island_title} - {updateDate?.item?.[0]?.title}
              <div className="myt-body-r">
                <div className="myt-body-r-box">
                  <div className="myt-body-r-box-title">Date</div>
                  <div className="myt-body-r-box-val">
                    {timeToYYYYMMDD(
                      +new Date(updateDate?.item?.[0]?.created_at)
                    )}
                  </div>
                </div>
              </div>
            </div>
            <p
              className="myt-body-r-box-p update-single"
              style={{ fontSize: '17px' }}
              dangerouslySetInnerHTML={{
                __html: updateDate?.item?.[0]?.content
              }}
            ></p>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default UpdateSingle;
