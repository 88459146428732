import {
  Filler,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import TreasuryContext from '../../../context/private/treasuryContext';
import { convertTokenPrice } from '../../../utils/tools';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  barPercentage: 0.45,
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {}
  },
  plugins: {
    legend: {
      display: true,
      position: 'top'
    },
    filler: {
      propagate: false
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          label += '$' + context.parsed.y.toLocaleString();
          return label;
        }
      }
    }
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [1, 10, 21, 14, 12],
      backgroundColor: '#292D30',
      borderRadius: 8,
      borderSkipped: false
    },
    {
      label: 'Dataset 2',
      data: [17, 15, 27, 14, 15],
      backgroundColor: '#34B3F1',
      borderRadius: 8,
      borderSkipped: false
    }
  ]
};

export function TxChartMonthly({ data }) {
  const { islandsDetailData } = useContext(TreasuryContext);

  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const convertedTime = [];
  data?.timstamp.map((t) => {
    const tempDate = new Date(Number(t));
    convertedTime.push(monthNames[tempDate.getMonth()]);
  });

  const incomeModified = data?.income.map((i) =>
    islandsDetailData?.is_token_based === 1
      ? convertTokenPrice(i, islandsDetailData?.token_price)
      : i
  );
  const outcomeModified = data?.outcome.map((i) =>
    islandsDetailData?.is_token_based === 1
      ? convertTokenPrice(i, islandsDetailData?.token_price)
      : i
  );
  const profitModified = data?.profit.map((i) =>
    islandsDetailData?.is_token_based === 1
      ? convertTokenPrice(i, islandsDetailData?.token_price)
      : i
  );

  const chartData = {
    labels: convertedTime,
    datasets: [
      {
        label: 'Deposits',
        data: incomeModified,
        backgroundColor: '#34B3F1',
        borderRadius: 8,
        borderSkipped: false
      },
      {
        label: 'Withdrawals',
        data: outcomeModified,
        backgroundColor: '#292D30',
        borderRadius: 8,
        borderSkipped: false
      },
      {
        label: 'Profit',
        data: profitModified,
        backgroundColor: '#8a2be2',
        borderRadius: 8,
        borderSkipped: false
      }
    ]
  };
  return (
    <Bar
      options={{
        ...options,
        plugins: {
          ...options.plugins,
          tooltip: {
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += '$' + context.parsed.y.toLocaleString();
                return label;
              }
            }
          }
        }
      }}
      data={chartData}
    />
  );
}
