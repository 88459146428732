import { createContext, useState } from 'react';
import bosService from '../../services/bosService';
import settingService from '../../services/settingService';
const BosContext = createContext<any>({});

export const BosContextProvider = ({ children }: JSX.Element | any) => {
  const [socialPageData, setSocialPageData] = useState<any>(null);
  async function loadSocialPage() {
    await bosService.getSocialPage().then((data) => setSocialPageData(data[0]));
  }

  const [academyPageData, setAcademyPageData] = useState<any>(null);
  async function loadAcademyPage() {
    await bosService
      .getAcademyPage()
      .then((data) => setAcademyPageData(data[0]));
  }

  const [offshorePageData, setOffshorePageData] = useState<any>(null);
  async function loadOffshorePage() {
    await bosService
      .getServiceOffshorePage()
      .then((data) => setOffshorePageData(data[0]));
  }

  const [debitcardPageData, setDebitcardPageData] = useState<any>(null);
  async function loadDebitcardPage() {
    await bosService
      .getServiceUltimoPage()
      .then((data) => setDebitcardPageData(data[0]));
  }

  const [lifestylePageData, setLifestylePageData] = useState<any>(null);
  async function loadLifestylePage() {
    await bosService
      .getLifestylePage()
      .then((data) => setLifestylePageData(data[0]));
  }

  const [eventsPageData, setEventsPageData] = useState<any>(null);
  async function loadEventsPage() {
    await bosService.getEventsPage().then((data) => setEventsPageData(data[0]));
  }

  const [shopPageData, setShopPageData] = useState<any>(null);
  async function loadShopPage() {
    await bosService.getShopPage().then((data) => setShopPageData(data[0]));
  }

  const [charityPageData, setCharityPageData] = useState<any>(null);
  async function loadCharityPage() {
    await bosService
      .getCharityPage()
      .then((data) => setCharityPageData(data[0]));
  }

  const [helpdeskPageData, setHelpdeskPageData] = useState<any>(null);
  async function loadHelpdeskPage() {
    await bosService
      .getHelpdeskPage()
      .then((data) => setHelpdeskPageData(data[0]));
  }

  const [tutorialsPageData, setTutorialsPageData] = useState<any>(null);
  async function loadTutorialsPage() {
    await bosService
      .getTutorialsPage()
      .then((data) => setTutorialsPageData(data[0]));
  }

  const [updatesPageData, setUpdatesPageData] = useState<any>(null);
  async function loadUpdatesPage(currentPage: any) {
    await bosService
      .getUpdatesPage(currentPage)
      .then((data) => setUpdatesPageData(data));
  }

  const [aboutPageData, setAboutPageData] = useState<any>(null);
  async function loadAboutPage() {
    await bosService.getAboutPage().then((data) => setAboutPageData(data[0]));
  }

  const [dashboardFaqPageData, setDashboardFaqPageData] = useState<any>(null);
  async function loadDashboardFaqPage() {
    await bosService
      .getDashboardFaqPage()
      .then((data) => setDashboardFaqPageData(data[0]));
  }

  const [bosFaqPageData, setBosFaqPageData] = useState<any>(null);
  async function loadBosFaqPage() {
    await bosService.getBosFaqPage().then((data) => setBosFaqPageData(data[0]));
  }

  const [bosVideoPageData, setBosVideoPageData] = useState<any>(null);
  async function loadBosVideoPage() {
    await bosService
      .getBosVideoPage()
      .then((data) => setBosVideoPageData(data[0]));
  }

  const [dashboardTutorialsPageData, setDashboardTutorialsPageData] =
    useState<any>(null);
  async function loadDashboardTutorialsPage() {
    await bosService
      .getDashboardTutorialsPage()
      .then((data) => setDashboardTutorialsPageData(data[0]));
  }

  const [cryptoTutorialsPageData, setCryptoTutorialsPageData] =
    useState<any>(null);
  async function loadCryptoTutorialsPage() {
    await bosService
      .getCryptoTutorialsPage()
      .then((data) => setCryptoTutorialsPageData(data[0]));
  }

  const [bosServicePageData, setBosServicePageData] = useState<any>(null);
  async function loadBosServicePage() {
    await bosService
      .getBosServicePage()
      .then((data) => setBosServicePageData(data[0]));
  }

  const [bosMeetingPageData, setBosMeetingPageData] = useState<any>(null);
  async function loadBosMeetingPage() {
    await bosService
      .getBosMeetingPage()
      .then((data) => setBosMeetingPageData(data[0]));
  }

  const [bosQAPageData, setBosQAPageData] = useState<any>(null);
  async function loadBosQAPage() {
    await bosService.getBosQAPage().then((data) => setBosQAPageData(data[0]));
  }

  const [bosWebinarsPageData, setBosWebinarsPageData] = useState<any>(null);
  async function loadBosWebinarsPage() {
    await bosService
      .getBosWebinarsPage()
      .then((data) => setBosWebinarsPageData(data[0]));
  }

  const [bosImblPageData, setBosImblPageData] = useState<any>(null);
  async function loadBosImblPage() {
    await bosService
      .getBosImblPage()
      .then((data) => setBosImblPageData(data[0]));
  }

  const [bosRecommendationsPageData, setBosRecommendationsPageData] =
    useState<any>(null);
  async function loadBosRecommendationsPage() {
    await bosService
      .getBosRecommendationsPage()
      .then((data) => setBosRecommendationsPageData(data[0]));
  }

  const [bosStrategySessionPageData, setBosStrategySessionPageData] =
    useState<any>(null);
  async function loadBosStrategySessionPage() {
    await bosService
      .getBosStrategySessionPage()
      .then((data) => setBosStrategySessionPageData(data[0]));
  }

  const [bosNevisPageData, setBosNevisPageData] = useState<any>(null);
  async function loadBosNevisPage() {
    await bosService
      .getBosNevisPage()
      .then((data) => setBosNevisPageData(data[0]));
  }

  const [bosNevisRegistrationPageData, setBosNevisRegistrationPageData] =
    useState<any>(null);
  async function loadBosNevisRegistrationPage() {
    await bosService
      .getBosNevisRegistrationPage()
      .then((data) => setBosNevisRegistrationPageData(data[0]));
  }

  const [bosStoriesPageData, setBosStoriesPageData] = useState<any>(null);
  async function loadBosStoriesPage() {
    await bosService
      .getBosStoriesPage()
      .then((data) => setBosStoriesPageData(data[0]));
  }

  const [bosWithdrawPageData, setBosWithdrawPageData] = useState<any>(null);
  async function loadBosWithdrawPage() {
    await bosService
      .getBosWithdrawPage()
      .then((data) => setBosWithdrawPageData(data[0]));
  }

  const [bosDepositTutorialPageData, setBosDepositTutorialPageData] =
    useState<any>(null);
  async function loadBosDepositTutorialPage() {
    await bosService
      .getBosDepositTutorialPage()
      .then((data) => setBosDepositTutorialPageData(data[0]));
  }

  const [bosCryptoDepositPageData, setBosCryptoDepositPageData] =
    useState<any>(null);
  async function loadBosCryptoDepositPage() {
    await bosService
      .getBosCryptoDepositPage()
      .then((data) => setBosCryptoDepositPageData(data[0]));
  }

  const [bosBankDepositPageData, setBosBankDepositPageData] =
    useState<any>(null);
  async function loadBosBankDepositPage() {
    await bosService
      .getBosBankDepositPage()
      .then((data) => setBosBankDepositPageData(data[0]));
  }

  const [bosOffshoreBankingPageData, setBosOffshoreBankingPageData] =
    useState<any>(null);
  async function loadBosOffshoreBankingPage() {
    await bosService
      .getBosOffshoreBankingPage()
      .then((data) => setBosOffshoreBankingPageData(data[0]));
  }

  const [bosCayeePageData, setBosCayeePageData] = useState<any>(null);
  async function loadBosCayeePage() {
    await bosService
      .getBosCayeePage()
      .then((data) => setBosCayeePageData(data[0]));
  }
  const [bosMorganPageData, setBosMorganPageData] = useState<any>(null);
  async function loadBosMorganPage() {
    await bosService
      .getBosMorganPage()
      .then((data) => setBosMorganPageData(data[0]));
  }
  const [tradingIslandTransitionPageData, setTradingIslandTransitionPageData] =
    useState<any>(null);
  async function loadTradingIslandTransitionPage() {
    await bosService
      .getTradingIslandTransitionPage()
      .then((data) => setTradingIslandTransitionPageData(data[0]));
  }

  const [bosChasePageData, setBosChasePageData] = useState<any>(null);
  async function loadBosChasePage() {
    await bosService
      .getBosChasePage()
      .then((data) => setBosChasePageData(data[0]));
  }

  const [bosWealthBlueprintPageData, setBosWealthBlueprintPageData] =
    useState<any>(null);
  async function loadBosWealthBlueprintPage() {
    await settingService
      .getCkEditorById(23)
      .then((data) => setBosWealthBlueprintPageData(data));
  }

  const [bosMyStrategyPageData, setBosMyStrategyPageData] = useState<any>(null);
  async function loadBosMyStrategyPage() {
    await settingService
      .getCkEditorById(22)
      .then((data) => setBosMyStrategyPageData(data));
  }

  const [bosBeaconsPageData, setBosBeaconsPageData] = useState<any>(null);
  async function loadBosBeaconsPage() {
    await settingService
      .getCkEditorById(25)
      .then((data) => setBosBeaconsPageData(data));
  }

  const [bosOnBoardingModalData, setBosOnBoardingModalData] =
    useState<any>(null);
  async function loadBosOnBoardingModal() {
    await settingService
      .getCkEditorById(26)
      .then((data) => setBosOnBoardingModalData(data));
  }

  const [bosTreasuryStrategyPageData, setBosTreasuryStrategyPageData] =
    useState<any>(null);
  async function loadBosTreasuryStrategyPage() {
    await settingService
      .getCkEditorById(24)
      .then((data) => setBosTreasuryStrategyPageData(data));
  }
  const [singleUpdateData, setSingleUpdateData] = useState<any>(null);
  async function getSingleUpdateData(slug: string) {
    await bosService
      .getUpdatesSingle(slug)
      .then((data) => setSingleUpdateData(data));
  }

  const context = {
    bosOnBoardingModalData,
    loadBosOnBoardingModal,
    bosBeaconsPageData,
    loadBosBeaconsPage,
    bosMyStrategyPageData,
    loadBosMyStrategyPage,
    bosTreasuryStrategyPageData,
    loadBosTreasuryStrategyPage,
    bosWealthBlueprintPageData,
    loadBosWealthBlueprintPage,
    socialPageData,
    loadSocialPage,
    academyPageData,
    loadAcademyPage,
    offshorePageData,
    loadOffshorePage,
    debitcardPageData,
    loadDebitcardPage,
    lifestylePageData,
    loadLifestylePage,
    eventsPageData,
    loadEventsPage,
    shopPageData,
    loadShopPage,
    charityPageData,
    loadCharityPage,
    helpdeskPageData,
    loadHelpdeskPage,
    tutorialsPageData,
    loadTutorialsPage,
    updatesPageData,
    loadUpdatesPage,
    aboutPageData,
    loadAboutPage,
    dashboardFaqPageData,
    loadDashboardFaqPage,
    bosFaqPageData,
    loadBosFaqPage,
    bosVideoPageData,
    loadBosVideoPage,
    dashboardTutorialsPageData,
    loadDashboardTutorialsPage,
    cryptoTutorialsPageData,
    loadCryptoTutorialsPage,
    bosServicePageData,
    loadBosServicePage,
    bosMeetingPageData,
    loadBosMeetingPage,
    bosQAPageData,
    loadBosQAPage,
    bosWebinarsPageData,
    loadBosWebinarsPage,
    bosImblPageData,
    loadBosImblPage,
    bosRecommendationsPageData,
    loadBosRecommendationsPage,
    bosStrategySessionPageData,
    loadBosStrategySessionPage,
    bosNevisPageData,
    loadBosNevisPage,
    bosNevisRegistrationPageData,
    loadTradingIslandTransitionPage,
    tradingIslandTransitionPageData,
    loadBosNevisRegistrationPage,
    bosStoriesPageData,
    loadBosStoriesPage,
    bosWithdrawPageData,
    loadBosWithdrawPage,
    bosOffshoreBankingPageData,
    loadBosOffshoreBankingPage,
    bosCayeePageData,
    loadBosCayeePage,
    loadBosMorganPage,
    bosMorganPageData,
    setBosMorganPageData,
    bosChasePageData,
    loadBosChasePage,
    bosDepositTutorialPageData,
    loadBosDepositTutorialPage,
    bosCryptoDepositPageData,
    loadBosCryptoDepositPage,
    bosBankDepositPageData,
    loadBosBankDepositPage,
    singleUpdateData,
    setSingleUpdateData,
    getSingleUpdateData
  };

  return <BosContext.Provider value={context}>{children}</BosContext.Provider>;
};

export default BosContext;
