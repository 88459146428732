import { useContext, useEffect, useState } from 'react';
import { useWindowWidth } from '../breakpoint';
import './style.css';
import TreasuryContext from '../../../context/private/treasuryContext';
import { numberWithCommas } from '../../../utils/tools';
import AccountContext from '../../../context/private/accountContext';
import PrivateContext from '../../../context/private/privateContext';
import dictionary from '../../../locals/my-treasures.json';
import { ModalMethods, SuccessModal } from '../Success';
import { Link } from 'react-router-dom';
import { Flex } from '../../../styles/Styles';
import toast from 'react-hot-toast';
import vector6 from '../assets/vector-6@2x.png';
import dottedLine from '../assets/line-9@2x.png';
import line7 from '../assets/line-7.png';
import closeIcon from '../assets/union-3@2x.png';

export const IslandWithdrawalModal = ({
  forceWithdrawAmount,
  setForceWithdrawAmount
}) => {
  const screenWidth = useWindowWidth();

  const { settings, setUpdateTimer } = useContext(PrivateContext);

  const [isTermsCheck, setTermsCheck] = useState('');
  const [withdraw2FAToken, setWithdraw2FAToken] = useState('');
  const {
    islandClaimWindow,
    loadProfitChart,
    loadIslandClaimWithdraw,
    modalForceWithdrawAreYouSure,
    setModalForceWithdrawAreYouSure,
    modalTopUpAreYouSure,
    setModalTopUpAreYouSure,
    profitChartDailyData,
    profitChartMonthlyData,
    approximatlyAvailable,
    loadTransactionChart,
    transactionChartDailyData,
    transactionChartMonthlyData,
    islandsDetailData,
    crewData,
    modalTopUp,
    setModalTopUp,
    modalClaim,
    setModalClaim,
    modalForceWithdraw,
    setModalForceWithdraw,
    topUpHandle,
    islandData,
    claimHandle,
    claimHandleForType2,
    forceWithdrawHandle,
    currentIslandId,
    ckeditorForceWithdrawModal,
    loadCkEditorByID,
    forceWithdrawSuccessData,
    setForceWithdrawSuccessData
  } = useContext(TreasuryContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    setUpdateTimer(+new Date());
    modalForceWithdraw === false && setForceWithdrawSuccessData(null);
  }, [modalForceWithdraw]);

  useEffect(() => {
    loadCkEditorByID(0);
  }, []);

  return (
    <>
      {!forceWithdrawSuccessData && modalForceWithdraw && (
        <div
          className="blur-bg"
          onClick={() => setModalForceWithdraw(false)}
        ></div>
      )}

      {!forceWithdrawSuccessData && modalForceWithdraw && (
        <div className="island-withdrawal-modal">
          <div
            className="modal-mobile"
            style={{
              height:
                screenWidth < 1037
                  ? '1355px'
                  : screenWidth >= 1037
                  ? '772px'
                  : undefined,
              overflow: screenWidth < 1037 ? 'hidden' : undefined,
              width:
                screenWidth < 1037
                  ? '564px'
                  : screenWidth >= 1037
                  ? '1037px'
                  : undefined
            }}
          >
            <div
              className="content"
              style={{
                alignItems:
                  screenWidth < 1037
                    ? 'flex-start'
                    : screenWidth >= 1037
                    ? 'center'
                    : undefined,
                flexDirection: screenWidth < 1037 ? 'column' : undefined,
                gap: screenWidth < 1037 ? '20px' : undefined,
                justifyContent:
                  screenWidth >= 1037 ? 'space-between' : undefined,
                width:
                  screenWidth < 1037
                    ? '524px'
                    : screenWidth >= 1037
                    ? '989px'
                    : undefined
              }}
            >
              {screenWidth < 1037 && (
                <>
                  <div className="left">
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Island Name:</div>
                        <div className="text-wrapper-3">
                          {islandData?.[0]?.title}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">BOS Balance:</div>
                        <div className="text-wrapper-3">
                          {userInfo?.total_balance ||
                          userInfo?.total_balance === 0
                            ? `$${numberWithCommas(
                                Number(userInfo?.total_balance)
                              )}`
                            : 'Loading...'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Your Loan Allocation:
                        </div>
                        <div className="text-wrapper-3">
                          {islandsDetailData ? (
                            islandsDetailData?.balance ? (
                              `$${numberWithCommas(
                                islandsDetailData?.balance,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Available Loan Allocation (Unlocked):
                        </div>
                        <div className="text-wrapper-3">
                          {islandsDetailData ? (
                            islandsDetailData?.available_force_withdrawal ? (
                              `$${numberWithCommas(
                                islandsDetailData?.available_force_withdrawal,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="number"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={forceWithdrawAmount}
                        onChange={(e) =>
                          e.target.value !== 'e' &&
                          islandsDetailData &&
                          islandsDetailData?.available_force_withdrawal >=
                            +e.target.value &&
                          setForceWithdrawAmount(
                            e.target.value.length > 0
                              ? Number(e.target.value)
                              : ''
                          )
                        }
                      />
                      <div className="options">
                        <p className="min pointer">
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">No Limit</span>
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) =>
                            setForceWithdrawAmount(
                              Number(
                                Number(islandsDetailData?.activeAmount) +
                                  Number(islandsDetailData?.available_balance) <
                                  islandsDetailData?.available_force_withdrawal
                                  ? Number(islandsDetailData?.activeAmount) +
                                      Number(
                                        islandsDetailData?.available_balance
                                      )
                                  : islandsDetailData?.available_force_withdrawal
                              )
                            )
                          }
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandsDetailData?.activeAmount ||
                            islandsDetailData?.activeAmount === 0 ? (
                              `$${numberWithCommas(
                                Number(
                                  Number(islandsDetailData?.activeAmount) +
                                    Number(
                                      islandsDetailData?.available_balance
                                    ) <
                                    islandsDetailData?.available_force_withdrawal
                                    ? Number(islandsDetailData?.activeAmount) +
                                        Number(
                                          islandsDetailData?.available_balance
                                        )
                                    : islandsDetailData?.available_force_withdrawal
                                ),
                                0
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      {forceWithdrawAmount
                        ? Number(islandsDetailData?.activeAmount) -
                            Number(forceWithdrawAmount) <
                            Number(islandsDetailData?.minimum_invest) && (
                            <div className="warning-message">
                              <b style={{ color: 'red' }}>
                                {'Warning: Island Deactivation'}
                              </b>
                              <p>
                                The loan allocation will be less than the
                                minimum requirement and the island will be
                                deactivated.
                              </p>
                            </div>
                          )
                        : ''}
                      <div className="overlap-group">
                        <p className="please-ensure-to">
                          <span
                            className="text-wrapper-6"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorForceWithdrawModal
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-4@2x.png"
                                  />
                                </div>
                                <img
                                  className="img"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(forceWithdrawAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="frame">
                      <div className="group">
                        <div className="overlap-group-3">
                          <div className="rectangle-2" />
                          <div className="payment-details">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">
                                  Forced Withdrawal
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">BOS Fee</div>
                                <div className="text-wrapper-3">
                                  {islandsDetailData?.penalty_fee_percent}%
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(forceWithdrawAmount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line"
                              alt="Line"
                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-6@2x.png"
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">BOS Fee</div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    forceWithdrawAmount *
                                      (islandsDetailData?.penalty_fee_percent /
                                        100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Received Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(forceWithdrawAmount) -
                                      forceWithdrawAmount *
                                        (islandsDetailData?.penalty_fee_percent /
                                          100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(userInfo?.total_balance) +
                                      (Number(forceWithdrawAmount) -
                                        forceWithdrawAmount *
                                          (islandsDetailData?.penalty_fee_percent /
                                            100))
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-2">
                        <input
                          className="checkbox-size"
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setTermsCheck(e.target.checked)}
                        />
                        <label htmlFor="horns">
                          <p className="p">
                            I have reviewed and agree to the{' '}
                            <Link
                              to={`/island-page?id=${currentIslandId}&tab=conditions`}
                              target="_blank"
                            >
                              Island Conditions.
                            </Link>
                          </p>
                        </label>
                      </div>
                      <div className="item">
                        <Flex direction="col" h="unset">
                          <input
                            type="number"
                            placeholder="2FA Code"
                            className="mod-inside-body-input"
                            style={{
                              background: 'whitesmoke',
                              border: '1px solid #EEEEEE'
                            }}
                            value={withdraw2FAToken}
                            onChange={(e) =>
                              e.target.value.length <= 6 &&
                              setWithdraw2FAToken(e.target.value)
                            }
                          />

                          <div
                            className="div-wrapper"
                            style={
                              isTermsCheck && withdraw2FAToken.length === 6
                                ? {}
                                : { opacity: 0.3, cursor: 'not-allowed' }
                            }
                            onClick={() => {
                              if (
                                isTermsCheck &&
                                withdraw2FAToken.length === 6
                              ) {
                                if (islandsDetailData) {
                                  if (forceWithdrawAmount > 0) {
                                    if (
                                      Number(forceWithdrawAmount) <=
                                      Number(islandsDetailData?.activeAmount)
                                    ) {
                                      if (withdraw2FAToken.length === 6) {
                                        forceWithdrawHandle(
                                          forceWithdrawAmount,
                                          currentIslandId,
                                          withdraw2FAToken,
                                          setForceWithdrawAmount,
                                          setWithdraw2FAToken
                                        );
                                      } else {
                                        toast.error(
                                          `Please enter a valid token`
                                        );
                                      }
                                    } else {
                                      toast.error(
                                        `Your active fund is $${islandsDetailData?.activeAmount}`
                                      );
                                    }
                                  } else {
                                    toast.error(dictionary['TREASURE-title32']);
                                  }
                                }
                              }
                            }}
                          >
                            <div className="text-wrapper-12">Submit</div>
                          </div>
                        </Flex>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}

              {screenWidth >= 1037 && (
                <>
                  <div className="left-2">
                    <div className="details">
                      <div className="payment-detail">
                        <div className="text-wrapper-2">Island Name:</div>
                        <div className="text-wrapper-3">
                          {islandData?.[0]?.title}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">BOS Balance:</div>
                        <div className="text-wrapper-3">
                          {userInfo?.total_balance ||
                          userInfo?.total_balance === 0
                            ? `$${numberWithCommas(
                                Number(userInfo?.total_balance)
                              )}`
                            : 'Loading...'}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Your Loan Allocation:
                        </div>
                        <div className="text-wrapper-3">
                          {islandsDetailData ? (
                            islandsDetailData?.balance ? (
                              `$${numberWithCommas(
                                islandsDetailData?.balance,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                      <div className="payment-detail">
                        <div className="text-wrapper-2">
                          Available Loan Allocation (Unlocked):
                        </div>
                        <div className="text-wrapper-3">
                          {islandsDetailData ? (
                            islandsDetailData?.available_force_withdrawal ? (
                              `$${numberWithCommas(
                                islandsDetailData?.available_force_withdrawal,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="enterance">
                      <input
                        type="number"
                        placeholder="Enter an amount"
                        className="input mod-inside-body-input"
                        value={forceWithdrawAmount}
                        onChange={(e) =>
                          e.target.value !== 'e' &&
                          islandsDetailData &&
                          islandsDetailData?.available_force_withdrawal >=
                            +e.target.value &&
                          setForceWithdrawAmount(
                            e.target.value.length > 0
                              ? Number(e.target.value)
                              : ''
                          )
                        }
                      />
                      <div className="options">
                        <p className="min">
                          <span className="span">Min:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">No Limit</span>
                          {/* <span className="text-wrapper-5">{islandsDetailData ? `$${numberWithCommas(islandsDetailData?.min_claim)}` : ''}</span> */}
                        </p>
                        <p
                          className="max pointer"
                          onClick={(e) =>
                            setForceWithdrawAmount(
                              Number(
                                Number(islandsDetailData?.activeAmount) +
                                  Number(islandsDetailData?.available_balance) <
                                  islandsDetailData?.available_force_withdrawal
                                  ? Number(islandsDetailData?.activeAmount) +
                                      Number(
                                        islandsDetailData?.available_balance
                                      )
                                  : islandsDetailData?.available_force_withdrawal
                              )
                            )
                          }
                        >
                          <span className="span">Max:</span>
                          <span className="text-wrapper-4">&nbsp;</span>
                          <span className="text-wrapper-5">
                            {islandsDetailData?.activeAmount ||
                            islandsDetailData?.activeAmount === 0 ? (
                              `$${numberWithCommas(
                                Number(
                                  Number(islandsDetailData?.activeAmount) +
                                    Number(
                                      islandsDetailData?.available_balance
                                    ) <
                                    islandsDetailData?.available_force_withdrawal
                                    ? Number(islandsDetailData?.activeAmount) +
                                        Number(
                                          islandsDetailData?.available_balance
                                        )
                                    : islandsDetailData?.available_force_withdrawal
                                ),
                                0
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="description">
                      {forceWithdrawAmount
                        ? Number(islandsDetailData?.activeAmount) -
                            Number(forceWithdrawAmount) <
                            Number(islandsDetailData?.minimum_invest) && (
                            <div className="warning-message">
                              <b style={{ color: 'red' }}>
                                {'Warning: Island Deactivation'}
                              </b>
                              <p>
                                The loan allocation will be less than the
                                minimum requirement and the island will be
                                deactivated
                              </p>
                            </div>
                          )
                        : ''}
                      <div
                        className="overlap-group-4"
                        style={
                          forceWithdrawAmount &&
                          Number(islandsDetailData?.activeAmount) -
                            Number(forceWithdrawAmount) <
                            Number(islandsDetailData?.minimum_invest)
                            ? {
                                height: '285px'
                              }
                            : {}
                        }
                      >
                        <p className="please-ensure-to">
                          <span
                            className="text-wrapper-6"
                            dangerouslySetInnerHTML={{
                              __html: ckeditorForceWithdrawModal
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <img className="line-3" alt="Line" src={line7} />
                  <div className="right-2">
                    <div className="header">
                      <div className="success-icon">
                        <div className="overlap">
                          <div className="vuesax-bold-tick">
                            <div className="tick-circle-wrapper">
                              <div className="tick-circle">
                                <div className="overlap-group-2">
                                  <div className="rectangle" />
                                  <img
                                    className="vector"
                                    alt="Vector"
                                    src={vector6}
                                  />
                                </div>
                                <img
                                  className="vector-2"
                                  alt="Vector"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/vector-5@2x.png"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-text">
                        <div className="text-wrapper-10">
                          Your Requested Amount
                        </div>
                        <div className="text-wrapper-11">
                          ${numberWithCommas(forceWithdrawAmount)}
                        </div>
                      </div>
                    </div>
                    <div className="frame-3">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="rectangle-3" />
                          <div className="payment-details-2">
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Payment Method
                                </div>
                                <div className="text-wrapper-3">
                                  Forced Withdrawal
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">BOS Fee</div>
                                <div className="text-wrapper-3">
                                  {islandsDetailData?.penalty_fee_percent}%
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Requested Amount
                                </div>
                                <div className="text-wrapper-3">
                                  ${numberWithCommas(forceWithdrawAmount)}
                                </div>
                              </div>
                            </div>
                            <img
                              className="line-4"
                              alt="Line"
                              src={dottedLine}
                            />
                            <div className="div-2">
                              <div className="payment-detail">
                                <div className="text-wrapper-2">BOS Fee</div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    forceWithdrawAmount *
                                      (islandsDetailData?.penalty_fee_percent /
                                        100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <div className="text-wrapper-2">
                                  Received Amount
                                </div>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(forceWithdrawAmount) -
                                      forceWithdrawAmount *
                                        (islandsDetailData?.penalty_fee_percent /
                                          100)
                                  )}
                                </div>
                              </div>
                              <div className="payment-detail">
                                <p className="text-wrapper-2">
                                  Your BOS Balance will be
                                </p>
                                <div className="text-wrapper-3">
                                  $
                                  {numberWithCommas(
                                    Number(userInfo?.total_balance) +
                                      (Number(forceWithdrawAmount) -
                                        forceWithdrawAmount *
                                          (islandsDetailData?.penalty_fee_percent /
                                            100))
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-2">
                        <input
                          className="checkbox-size"
                          type="checkbox"
                          id="horns"
                          name="horns"
                          onChange={(e) => setTermsCheck(e.target.checked)}
                        />
                        <label htmlFor="horns">
                          <p className="p">
                            I have reviewed and agree to the{' '}
                            <Link
                              to={`/island-page?id=${currentIslandId}&tab=conditions`}
                              target="_blank"
                            >
                              Island Conditions.
                            </Link>
                          </p>
                        </label>
                      </div>
                      <div className="overlap-wrapper">
                        <Flex direction="col" h="unset">
                          <input
                            type="number"
                            placeholder="2FA Code"
                            className="mod-inside-body-input"
                            style={{
                              background: 'whitesmoke',
                              border: '1px solid #EEEEEE'
                            }}
                            value={withdraw2FAToken}
                            onChange={(e) =>
                              e.target.value.length <= 6 &&
                              setWithdraw2FAToken(e.target.value)
                            }
                          />

                          <div
                            className="overlap-2"
                            style={
                              isTermsCheck && withdraw2FAToken.length === 6
                                ? {}
                                : { opacity: 0.3, cursor: 'not-allowed' }
                            }
                            onClick={() => {
                              if (
                                isTermsCheck &&
                                withdraw2FAToken.length === 6
                              ) {
                                if (islandsDetailData) {
                                  if (forceWithdrawAmount > 0) {
                                    if (
                                      Number(forceWithdrawAmount) <=
                                      Number(islandsDetailData?.activeAmount)
                                    ) {
                                      if (withdraw2FAToken.length === 6) {
                                        forceWithdrawHandle(
                                          forceWithdrawAmount,
                                          currentIslandId,
                                          withdraw2FAToken,
                                          setForceWithdrawAmount,
                                          setWithdraw2FAToken
                                        );
                                      } else {
                                        toast.error(
                                          `Please enter a valid token`
                                        );
                                      }
                                    } else {
                                      toast.error(
                                        `Your active fund is $${islandsDetailData?.activeAmount}`
                                      );
                                    }
                                  } else {
                                    toast.error(dictionary['TREASURE-title32']);
                                  }
                                }
                              }
                            }}
                          >
                            <div className="text-wrapper-13">Submit</div>
                          </div>
                        </Flex>
                      </div>
                    </div>
                    <img
                      className="line-2"
                      alt="Line"
                      src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7361b8264fdbeecae76b1/img/line-5@2x.png"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className="hr"
              style={{
                width:
                  screenWidth < 1037
                    ? '565px'
                    : screenWidth >= 1037
                    ? '1037px'
                    : undefined
              }}
            />
            <div
              className="header-2"
              style={{
                width:
                  screenWidth < 1037
                    ? '516px'
                    : screenWidth >= 1037
                    ? '993px'
                    : undefined
              }}
            >
              <div className="text-wrapper-14">Island Withdrawal</div>
              <div
                className="close-btn"
                onClick={() => setModalForceWithdraw(false)}
              >
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                    screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union.svg'
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {modalForceWithdraw && forceWithdrawSuccessData && (
        <SuccessModal
          requestedAmount={forceWithdrawSuccessData?.requestedAmount}
          txID={forceWithdrawSuccessData?.txID}
          method={ModalMethods.ForceWithdraw}
          time={forceWithdrawSuccessData?.time}
          fee={forceWithdrawSuccessData?.fee}
          finalAmount={forceWithdrawSuccessData?.finalAmount}
          closeState={setModalForceWithdraw}
        />
      )}
    </>
  );
};
