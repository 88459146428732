import { useContext, useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import PrivateContext from '../../../context/private/privateContext';
import TreasuryContext from '../../../context/private/treasuryContext';
import dictionary from '../../../locals/my-treasures.json';
import { Container, Flex } from '../../../styles/Styles';
import { numberWithCommas, numberWithCommasOld } from '../../../utils/tools';
import Header from '../../Header';
import { IslandWithdrawalModal } from '../../Modal/IslandWithdrawal';
import Layout from '../../layout/Index';

const Withdraw = () => {
  const { islandId } = useParams();
  const {
    loadIslandClaimWithdraw,
    islandsDetailData,
    modalTopUp,
    modalClaim,
    modalForceWithdraw,
    setModalForceWithdraw,
    islandData,
    currentIslandId,
    setCurrentIslandId,
    setClaimSuccessData,
    loadsIslandsDetail
  } = useContext(TreasuryContext);
  const { flowState, setUpdateTimer, setCurrentSelectedIslandId } =
    useContext(PrivateContext);

  useEffect(() => {
    setUpdateTimer(+new Date());
    setClaimSuccessData(null);
    modalClaim === false && islandId && loadIslandClaimWithdraw(islandId);
  }, [modalTopUp, modalClaim, modalForceWithdraw]);
  useEffect(() => {
    if (!currentIslandId) {
      setCurrentIslandId(islandId);
      setCurrentSelectedIslandId(islandId);
    }
  }, []);

  const [forceWithdrawAmount, setForceWithdrawAmount] = useState<any>(null);
  useEffect(() => {
    loadsIslandsDetail(islandId);
  }, [islandId]);

  const [islandBalance, setIslandBalance] = useState(
    islandsDetailData?.balance
  );

  const [islandBalanceInput, setIslandBalanceInput] = useState(
    islandsDetailData?.balance
  );
  useEffect(() => {
    setIslandBalance(islandsDetailData?.balance);
    setIslandBalanceInput(islandsDetailData?.balance);
  }, [islandsDetailData]);

  let tokenValueCalculator = islandBalance / islandsDetailData?.token_price;
  let tokenValue = islandsDetailData?.balance / islandsDetailData?.token_price;
  const Tooltip = ({ data }) => {
    return (
      <div
        className="tooltip-info tooltip-info-normal tooltip-info-normal-inside"
        style={{
          left: 0,
          width: 20,
          margin: '-6px 0px 0px 0px'
        }}
      >
        <AiOutlineInfoCircle className="tooltip-info-icon" />
        <div className="tooltip-info-text">
          <span>{data}</span>
        </div>
      </div>
    );
  };
  return (
    <Layout className="over">
      <Container x={'center'} y={'center'} className="container over-container">
        <Flex gap={1.7} x={'center'} className="over-flex">
          <div className="over-all pb-10">
            <div className="over-header">
              <Header title={islandData?.[0]?.title} />
            </div>
            <IslandWithdrawalModal
              forceWithdrawAmount={forceWithdrawAmount}
              setForceWithdrawAmount={setForceWithdrawAmount}
            />
            <div className="flex flex-col gap-6">
              <div className="mymap-s chart-section gap-4">
                <p className="mymap-s-h">{dictionary['TREASURE-title121']}</p>
                <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
                  <div className="participate-info-item">
                    <div className="data-container">
                      <div className="data-container-item">
                        <p className="data-title">
                          {dictionary['TREASURE-title44']}
                        </p>

                        <div className="data-value">
                          {islandsDetailData ? (
                            islandsDetailData?.balance ? (
                              `$${numberWithCommas(
                                islandsDetailData?.balance,
                                2
                              )}`
                            ) : (
                              '$0'
                            )
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="participate-info-item">
                    <div className="data-container">
                      <div className="data-container-item">
                        <p className="data-title">
                          {dictionary['TREASURE-title123']}
                        </p>

                        <div className="data-value">
                          {islandsDetailData ? (
                            <>
                              {Number(islandsDetailData?.token_price) === 0
                                ? 0
                                : numberWithCommasOld(tokenValue, 2)}{' '}
                              <span className="text-[12px]">
                                {islandsDetailData?.token_name}
                              </span>
                            </>
                          ) : (
                            <div className="skeleton-element-item skeleton-box"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {Number(islandsDetailData?.floor_token_price) > 0 ? (
                    <div className="participate-info-item">
                      <div className="data-container">
                        <div className="data-container-item">
                          <p className="data-title">
                            {dictionary['TREASURE-title125']}
                          </p>
                          <p className="data-value">
                            $
                            {Number(islandsDetailData?.token_price) === 0
                              ? 0
                              : numberWithCommas(
                                  Number(tokenValue) *
                                    Number(
                                      islandsDetailData?.floor_token_price
                                    ),
                                  2
                                )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="participate-info-item">
                    <div className="data-container">
                      <div className="data-container-item">
                        <p className="data-title">
                          {dictionary['TREASURE-title124']}
                        </p>
                        <p className="data-value">
                          $
                          {Number(islandsDetailData?.token_price) === 0
                            ? 0
                            : numberWithCommas(
                                Number(tokenValue) *
                                  Number(
                                    islandsDetailData?.current_token_price
                                  ),
                                2
                              )}
                        </p>
                      </div>
                    </div>
                  </div>
                  {Number(islandsDetailData?.book_token_price) > 0 ? (
                    <div className="participate-info-item">
                      <div className="data-container">
                        <div className="data-container-item">
                          <p className="data-title">
                            {dictionary['TREASURE-title126']}
                          </p>
                          <p className="data-value">
                            $
                            {Number(islandsDetailData?.token_price) === 0
                              ? 0
                              : numberWithCommas(
                                  Number(tokenValue) *
                                    Number(islandsDetailData?.book_token_price),
                                  2
                                )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mymap-s chart-section ">
                <p className="mymap-s-h">{dictionary['TREASURE-title120']}</p>
                <p
                  className="myt-body-r-box-p withdraw-p"
                  style={{ fontSize: '17px' }}
                  dangerouslySetInnerHTML={{
                    __html: islandsDetailData?.show_withdraw_ckeditor
                  }}
                ></p>
              </div>

              <div className="mymap-s chart-section gap-4">
                <p className="mymap-s-h">{dictionary['TREASURE-title122']}</p>

                <div className="flex flex-col gap-4 items-start justify-between">
                  <div className="flex gap-x-4 items-center lg:w-8/12 w-full">
                    <p className="text-[16px] ">
                      {dictionary['TREASURE-title131']}:
                    </p>
                    <input
                      type="number"
                      placeholder="Number"
                      className="register-input-input lg:max-w-[250px]"
                      value={islandBalanceInput}
                      autoComplete="off"
                      onChange={(e) => setIslandBalanceInput(e.target.value)}
                    />

                    <button
                      className="button-withdraw max-w-max px-4"
                      onClick={() => {
                        setIslandBalance(islandBalanceInput);
                      }}
                    >
                      {dictionary['TREASURE-title122']}
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 lg:w-4/12 w-full">
                    <div className="w-full flex gap-2 justify-between">
                      <p className="text-[16px]">
                        {dictionary['TREASURE-title123']}:
                      </p>
                      <p className="text-[16px] font-semibold text-[#535763]">
                        <>
                          {Number(islandsDetailData?.token_price) === 0
                            ? 0
                            : numberWithCommasOld(tokenValueCalculator, 2)}{' '}
                          <span className="text-[12px]">
                            {islandsDetailData?.token_name}
                          </span>
                        </>
                      </p>
                    </div>
                    {Number(islandsDetailData?.floor_token_price) > 0 ? (
                      <div className="w-full flex gap-2 justify-between">
                        <div className="text-[16px] flex gap-1">
                          {dictionary['TREASURE-title128']}:
                          <Tooltip
                            data={`Secured Floor Value is $
                         ${
                           Number(islandsDetailData?.token_price) === 0
                             ? 0
                             : numberWithCommasOld(
                                 islandsDetailData?.floor_token_price,
                                 2
                               )
                         } per token`}
                          />
                        </div>
                        <p className="text-[16px] font-semibold text-[#535763]">
                          $
                          {Number(islandsDetailData?.token_price) === 0
                            ? 0
                            : numberWithCommas(
                                Number(tokenValueCalculator) *
                                  Number(islandsDetailData?.floor_token_price),
                                2
                              )}
                        </p>
                      </div>
                    ) : null}
                    <div className="w-full flex gap-2 justify-between">
                      <div className="text-[16px] flex gap-1">
                        {dictionary['TREASURE-title129']}:
                        <Tooltip
                          data={
                            'Based on the Current Day Value your loan allocation would be'
                          }
                        />
                      </div>
                      <p className="text-[16px] font-semibold text-[#535763]">
                        $
                        {Number(islandsDetailData?.token_price) === 0
                          ? 0
                          : numberWithCommas(
                              Number(tokenValueCalculator) *
                                Number(islandsDetailData?.current_token_price),
                              2
                            )}
                      </p>
                    </div>
                    {Number(islandsDetailData?.book_token_price) > 0 ? (
                      <div className="w-full flex gap-2 justify-between">
                        <div className="text-[16px] flex gap-1">
                          {dictionary['TREASURE-title130']}:
                          <Tooltip
                            data={`Based on $
                         ${numberWithCommasOld(
                           islandsDetailData?.book_token_price,
                           2
                         )}, this will be the Book price`}
                          />
                        </div>
                        <p className="text-[16px] font-semibold text-[#535763]">
                          $
                          {Number(islandsDetailData?.token_price) === 0
                            ? 0
                            : numberWithCommas(
                                Number(tokenValueCalculator) *
                                  Number(islandsDetailData?.book_token_price),
                                2
                              )}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="over-chart">
                <div className="flex gap-2 flex-col lg:flex-row">
                  <div className="description-withdraw">
                    <p className="mymap-s-h mb-6">
                      {dictionary['TREASURE-title82']}
                    </p>
                    <p>{dictionary['TREASURE-title83']}</p>
                  </div>
                  <button
                    className="button-withdraw"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setModalForceWithdraw(true);
                    }}
                  >
                    {dictionary['TREASURE-title84']}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Withdraw;
